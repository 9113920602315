import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import RealEstateImage from "../images/real-estate-image.jpg"
import PrivateEquityImage from "../images/private-equity-image.jpg"
import SecuritiesImage from "../images/marketable-securities-image.jpg"

const IndexPage = ({ data }) => {
  const { html, frontmatter } = data.file.childMarkdownRemark;
  return (
    <Layout>
      <SEO title="Home"/>
      <section className="hero is-light is-medium is-carousel">
        <div className="background-image"/>
        <div className="background-image"/>
        <div className="background-image"/>
        <div className="background-image"/>
        <div className="background-image"/>
        <div className="background-image"/>
        <div className="hero-body">
          <div className="container">
            <h1 className="title">{frontmatter.headline}</h1>
            <h2 className="subtitle">{frontmatter.subhead}</h2>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content" dangerouslySetInnerHTML={{ __html: html }}/>
          <div className="columns">
            <div className="column">
              <Link to="/real-estate">
                <div className="card is-shadowless has-text-centered">
                  <div className="card-content" style={{paddingLeft: 0, paddingRight: 0}}>
                    <h3 className="title is-size-5">Real Estate</h3>
                  </div>
                  <div className="card-image">
                    <figure className="image is-2by3">
                      <img src={RealEstateImage}/>
                    </figure>
                  </div>
                </div>
              </Link>
            </div>
            <div className="column">
              <Link to="/private-equity">
                <div className="card is-shadowless has-text-centered">
                  <div className="card-content" style={{paddingLeft: 0, paddingRight: 0}}>
                    <h3 className="title is-size-5">Private Equity</h3>
                  </div>
                  <div className="card-image">
                    <figure className="image is-2by3">
                      <img src={PrivateEquityImage}/>
                    </figure>
                  </div>
                </div>
              </Link>
            </div>
            <div className="column">
              <Link to="/marketable-securities">
                <div className="card is-shadowless has-text-centered">
                  <div className="card-content" style={{paddingLeft: 0, paddingRight: 0}}>
                    <h3 className="title is-size-5">Marketable Securities</h3>
                  </div>
                  <div className="card-image">
                    <figure className="image is-2by3">
                      <img src={SecuritiesImage}/>
                    </figure>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    file(name: {eq: "home"}) {
      childMarkdownRemark {
        frontmatter {
          headline
        }
        html
      }
    }
  }
`

export default IndexPage
